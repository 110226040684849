.template-card {
    align-items: center;
    border: 2px solid transparent;
    border-radius: var(--rounded-md);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-block: 4px;
    overflow: hidden;
    padding: 15px;
    position: relative;
    width: 100%;
}

.template-card:hover,
.template-card[data-active='true'] {
    border: 2px solid var(--primary);
    /* box-shadow: 0 0 13px rgba(0, 0, 0, 0.3); */
}

.template-hr {
    background-color: var(--primary);
    height: 3px;
    margin-bottom: 16px;
    width: 50px;
}

.template-wrapper .slick-arrow {
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: var(--rounded-xl);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    top: calc(50% - 20px);
    width: 40px;
    z-index: 1;
    color: #000;
}

.template-wrapper .slick-arrow.slick-disabled {
    background-color: #f2f2f2 !important;
    cursor: not-allowed !important;
    color: #999 !important;
}

.template-wrapper .slick-arrow:hover span {
    color: #222 !important;
}

.template-wrapper .slick-arrow.slick-disabled:hover span {
    color: #999 !important;
}
