.news_feed_action {
    position: fixed;
    left: 22px;
    bottom: 70px;
    z-index: 100;
}

.news_feed_action button {
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #404040;
    background-color: #f0f0f0cc;
    border-radius: 50%;
    margin-top: 4px;
    cursor: pointer;
    border: none;
}

.news_feed_action button span {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news_feed {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.2);
    animation: openNewFeed linear 0.3s;
    min-height: 100vh;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.open-new-feed {
    overflow-y: hidden;
}

.news_feed-container {
    pointer-events: auto;
    min-height: 200px;
    width: 900px;
    max-height: calc(100vh - 80px);
    margin: 0;
    padding-left: 0;
    border-radius: 10px;
    background: #fff;
    filter: drop-shadow(0 -4px 32px rgba(0, 0, 0, 0.2));
    transition: all 0.3s ease-in-out;
    overflow: hidden auto;
    animation: scaleNewFeedOpen 0.3s;
    position: relative;
    display: flex;
    flex-direction: column;
}

.news_feed-header {
    position: sticky;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    align-items: center;
    top: 0;
    z-index: 9999;
    left: 0;
    right: 0;
    background: #fff;
}

.news_feed-close {
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    color: #4c4c4c;
    background: rgba(22, 24, 35, 0.08);
    border: none;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.25s;
}

.news_feed-close:hover {
    opacity: 1;
    cursor: pointer;
}

.news_feed-body {
    padding: 20px 20px 0;
    flex: 1;
    overflow-y: auto;
}

.news_feed-body-item {
    margin-bottom: 20px;
}

.news_feed.closing {
    animation: closeNewFeed linear 0.3s;
}

.news_feed.closing .news_feed-container {
    animation: scaleNewFeedClose 0.3s;
}

/* Editor */
.news_feed {
    color: #292929;
    word-wrap: break-word;
    line-height: 2;
    font-size: 16px;
}

.news_feed h1,
.news_feed h2,
.news_feed h3,
.news_feed h4,
.news_feed h5,
.news_feed h6 {
    font-weight: 700;
}

.news_feed h1 {
    font-size: 28px;
}

.news_feed h2 {
    font-size: 22px;
}

.news_feed h3 {
    font-size: 18px;
}

.news_feed h4 {
    font-size: 16px;
}

.news_feed img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 2px #0003;
}

.news_feed p > img + em,
.news_feed p > a:has(img) + em {
    display: block;
    text-align: center;
}

.news_feed a[href] {
    color: var(--primary);
    text-decoration: underline !important;
}

.news_feed code {
    padding: 1px 3px;
    border-radius: 3px;
    background-color: #c9fffc;
    white-space: pre-wrap;
    word-break: keep-all;
}

.news_feed h2[data-appended] {
    position: relative;
    scroll-margin-top: 100px;
}

.news_feed h2[data-appended]:hover a[data-link] {
    display: block;
}

.news_feed hr {
    border: none;
    border-color: transparent;
    height: 1px;
    background-color: #ddd;
    margin: 40px 0;
}

.news_feed p,
.news_feed ul,
.news_feed ol,
.news_feed table,
.news_feed blockquote {
    margin: 16px 0;
}

.news_feed blockquote {
    margin-left: 0;
    padding: 10px 10px 10px 20px;
    border-left: 3px solid var(--primary);
    color: #6d6d6d;
    font-weight: 600;
    background: none repeat scroll 0 0 rgba(102, 128, 153, 0.05);
}

.news_feed blockquote p {
    margin: 0;
}

.news_feed ul,
.news_feed ol {
    padding-left: 24px;
}

.news_feed ul {
    list-style: disc;
}

.news_feed ol {
    list-style: decimal;
}

.news_feed table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.news_feed table tr td,
.news_feed table tr th {
    text-align: left;
    border: 1px solid #e6dfdd;
    padding: 2px 4px 2px 12px;
}

.news_feed strong {
    font-weight: 700;
}

.news_feed em {
    font-weight: unset;
    font-style: italic;
}

.news_feed pre {
    background-color: #f5f5f5;
    border-radius: 0;
    overflow-x: auto;
    display: block;
    line-height: 28px;
    word-break: break-word;
}

.news_feed pre code {
    padding: 3px 0;
    margin: 0;
    word-break: normal;
    background-color: #f5f5f5;
    font-size: 14px;
    border-radius: 0;
    overflow-x: auto;
    color: #e83e8c;
}

p:has(img) {
    text-align: center;
}

@media (max-width: 740px) {
    .news_feed-container {
        margin: 0 8px;
        width: 100%;
    }
}

@keyframes openNewFeed {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes scaleNewFeedOpen {
    0% {
        scale: 0;
        opacity: 0;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
}

@keyframes closeNewFeed {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes scaleNewFeedClose {
    0% {
        scale: 1;
        opacity: 1;
    }
    100% {
        scale: 0;
        opacity: 0;
    }
}
