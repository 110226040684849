#root {
    --primary: #096eff;
    --link_color: #096eff;
    --success_color: #4caf50;
    --error_color: #f44336;
    --warning_color: #ff9800;
    --info_color: #096eff;
    --primary-rgb: 9, 110, 255;
    --rounded: 6px;
    --rounded-xs: 2px;
    --rounded-sm: 4px;
    --rounded-md: 8px;
    --rounded-lg: 12px;
    --rounded-xl: 20px;
    --window-height: 100vh;
}

.default__header {
    position: fixed;
    z-index: 10;
}

.default_layout-layout {
    background: #f3f6f9 !important;
    flex-direction: row;
    min-height: 100vh;
}

.default__header-hr {
    background: linear-gradient(rgba(9, 30, 66, 0.13), rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 0, rgba(9, 30, 66, 0) 4px);
    content: '';
    height: 4px;
    left: 10px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 8;
}

.default__header-logo {
    display: flex;
    align-items: center;
    height: 100%;
}

.header__logo-pc {
    height: 40px;
    user-select: none;
}

.default__header-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    margin-left: 13px;
}

.default-menu {
    color: #7a869a;
    height: 30px;
    margin-top: 20px;
    padding-left: 28px;
    border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.default-sider {
    z-index: 10;
    margin-top: 64px;
}

.default-sider::after {
    background: transparent;
    border-top: 1px solid var(--border);
    border-top-left-radius: 20px;
    box-shadow: -6px -6px #fff;
    content: '';
    height: 30px;
    position: absolute;
    right: -30px;
    top: 0;
    width: 30px;
    z-index: 10;
}

.default-content {
    transition: all 0.2s, background 0s;
    margin-top: 64px;
    padding: 20px;
    border-radius: 8px;
    min-height: calc(100vh - 64px);
    overflow: auto;
}

.menu-sidebar.ant-menu-light .ant-menu-submenu-arrow,
.menu-sidebar.ant-menu-light svg,
.menu-sidebar.ant-menu-light .ant-menu-title-content {
    color: #7a869a !important;
    font-weight: 600;
}

:where(.css-dev-only-do-not-override-mzwlov).ant-menu .ant-menu-item,
:where(.css-dev-only-do-not-override-mzwlov).ant-menu .ant-menu-submenu,
:where(.css-dev-only-do-not-override-mzwlov).ant-menu .ant-menu-submenu-title {
    border-radius: 20px;
}

.ant-menu-submenu svg,
.ant-menu-item svg {
    font-size: 1.14rem !important;
}

.menu-sidebar .ant-menu-submenu-selected svg,
.menu-sidebar .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content,
.menu-sidebar .ant-menu-submenu-selected .ant-menu-submenu-arrow,
.menu-sidebar .ant-menu-item-selected svg,
.menu-sidebar .ant-menu-item-selected .ant-menu-title-content {
    color: #096eff !important;
}

.ant-menu-item:hover:not(.ant-menu-item-selected) svg,
.ant-menu-item:hover:not(.ant-menu-item-selected) .ant-menu-title-content {
    color: rgba(0, 0, 0, 0.88) !important;
}

.box-header-text {
    align-items: center;
    background-color: #f3f3f4 !important;
    border-radius: var(--rounded-xl);
    cursor: pointer;
    display: flex;
    gap: 5px;
    height: 33px;
    padding-inline: 12px;
}

/* Footer */

.footer-tgc {
    margin-top: 48px;
}

.footer-wrapper {
    max-width: 100%;
    width: 1370px;
    margin: 0 auto;
}

.footer-wrapper .logo {
    width: 170px;
    height: 32px;
}

.footer-wrapper p {
    font-size: 16px;
    font-weight: 700;
}

.footer-wrapper a,
.footer-wrapper p,
.footer-wrapper svg {
    color: #9d9d9d;
}

.footer-wrapper p span {
    font-size: 15px;
    line-height: 22px;
}

.footer-wrapper ul {
    list-style: none;
}

.footer-wrapper .footer-icon li {
    display: inline-block;
    padding-left: 10px;
}

.footer-wrapper .footer-icon li:first-child {
    padding-left: 0;
}

.footer-wrapper .footer-icon img {
    width: 36px;
    height: 36px;
}

.footer-title {
    margin-bottom: 30px;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 16px;
}

.footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
}

@media (max-width: 550px) {
    .footer-tgc {
        margin-top: 20px;
    }

    .default-content {
        margin-left: 0;
        padding: 20px 0;
        border-radius: 0;
    }

    .default__header-logo a {
        display: none;
    }

    :where(.css-dev-only-do-not-override-mzwlov).ant-layout-footer {
        padding: 0 16px !important;
    }

    .footer-title {
        margin-top: 30px;
        margin-bottom: 16px;
    }

    .footer-bottom {
        flex-direction: column-reverse;
        gap: 16px;
        text-align: center;
        margin-bottom: 30px;
    }
}
